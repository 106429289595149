import React from 'react';

class Calendly extends React.Component {
    componentDidMount() {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
      head.appendChild(script);
    }
  
    render(){
      return (
          <section>
            <div>
              <div id="schedule_form">
                <div
                 className="calendly-inline-widget"
                 data-url="https://calendly.com/haibot/haibot-demonstration?hide_gdpr_banner=1"
                 style={{ minWidth: '320px', height: '690px' }} />
              </div>
            </div>
        </section>

      );
    }
  }

  export default Calendly;