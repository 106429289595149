import React from 'react';
import "./contact.scss"


export default function Contact() {

    return (
        <React.Fragment>
          <section class="contacthero">
            <div class="contactcontent">
              <h1>CONTACT US</h1>
            </div>
          </section>
          <div class="container" id='contactform'>
            <h2>Get In Touch</h2>
            <hr />
            <p>We’re here to simplify running your business! Speak With An Expert:</p>
            <form action="https://formsubmit.co/ce5f6aafbd15275227ecd98e04a60096" method="POST">
              <div class="form-group">
                <div class="form-row">
                  <div class="col-xs-12">
                    <input type="text" name="name" class="form-control" placeholder="Full Name" required/>
                  </div>
                  <div class="col-xs-12">
                    <input type="email" name="email" class="form-control" placeholder="Email Address" required/>
                  </div>
                  <div class="col">
                    <input type="hidden" name="_next" value="https://www.haibot.co.uk/thanks"/>
                  </div>
                  <div class="col">
                    <input type="hidden" name="_captcha" value="false"/>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <textarea placeholder="Your Message" class="form-control" name="message" rows="10" required></textarea>
              </div>
              <button type="submit" class="btn btn-lg btn-primary btn-block">Send Message</button>
            </form>
          </div>
        </React.Fragment>
    )
}