import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Intro from "./components/intro/intro";
import Contact from "./components/contact/contact";
import Demo from "./components/demo/demo";
import Thanks from "./components/thanks/thanks";
import Privacy from "./components/privacy/privacy";
import Terms from "./components/terms/terms";

import "./app.scss"

const Navigation = lazy(() => import('./components/topbar/Topbar'));
const Footer = lazy(() => import('./components/footer/footer'));



function App() {
  return (
  <>
  <Suspense fallback={<div />}>
    <Navigation/>
  </Suspense>
  <Routes>
   <Route path='/home' element={<Intro/>} />
   <Route exact path='/contact' element={<Contact/>} />
   <Route exact path='/demo' element={<Demo/>} />
   <Route exact path='/thanks' element={<Thanks/>} />
   <Route exact path='/privacy' element={<Privacy/>} />
   <Route exact path='/terms' element={<Terms/>} />
   <Route path="/" element={<Navigate replace to="/home" />} />
  </Routes>
  <Suspense>
    <Footer/>
  </Suspense>
  
 </>

  );
}


export default App;

