
import React from 'react';
import "./intro.scss"
import { Container, Row, Col, Button, Accordion } from "react-bootstrap";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import { Link } from 'react-router-dom';

import Homehero from '../../assets/images/homehero.jpg';

export default function Intro() {
  return (
    <React.Fragment>
      <section class="hero">
      <img 
      className="heroimage"
      src={Homehero}
      alt='Home hero'
       />
        <div class="content">
          <h1>Create Capacity</h1>
          <p>Delegate high-volume, repetitive bookkeeping, pre-compliance and payroll
           tasks to a digital robot, solve your capacity problems, spend more time 
           with your clients, and seize the full business potential of your organisation
           <br/><br/>
           <Link to="/demo"><Button variant="secondary" size="lg">Schedule a demo &gt;&gt;</Button></Link></p>
        </div>
      </section>
      <Container>
        <AnimationOnScroll duration={1.2} animateIn="animate__fadeIn">
            <Col className='intro' xs={{ span: 10, offset: 1 }} sm={{ span: 12, offset: 0 }}>
              <h3><i class="fa-solid fa-gears"></i></h3>
              <h1>Build your digital workforce.</h1>
              <br/>
              <h2>​Get back time to focus on what matters!</h2>
              <br/>
              <p>Haibot uses hyperautomation technology to deliver an augmented workforce 
               solution for accounting, bookkeeping and payroll services companies.<br/><br/>
              We build robot assistants to handle manual, repetitive bookkeeping, pre-compliance and payroll tasks so
              you can repurpose your employees&#39; time to better uses.</p>
            </Col>
            
            <Row className='benefits'>
                <Col lg={3}  xs={{ span: 10, offset: 1 }} sm={{ span: 12, offset: 0 }}>
                  <h1>Cost<br/> Saving <hr/></h1>
                  <p>Realise tremendous savings and scalability with hyperautomation instead of manual data entry. We offer pricing packages that assure a return on investment in less than 6 months. </p>
                </Col> 
                <Col lg={3} xs={{ span: 10, offset: 1 }} sm={{ span: 12, offset: 0 }}>
                  <h1>Designed to Delight <hr/></h1>
                  <p>Lots of information and data to validate and process? Your robot assistant doesn&#39;t mind. It will complete those boring manual data entry tasks that people hate to do (and shouldn't have to do).</p>
                </Col> 
                <Col lg={3} xs={{ span: 10, offset: 1 }} sm={{ span: 12, offset: 0 }}>
                  <h1>Improved Accuracy <hr/></h1>
                  <p>Your robot assistant will complete your data tasks in a logical, consistent manner. Reduce human error and see an improvement in your data accuracy. </p>
                </Col> 
                <Col lg={3} xs={{ span: 10, offset: 1 }} sm={{ span: 12, offset: 0 }}>
                  <h1>​Improve Productivity <hr/></h1>
                  <p>Your robot assistants can perform manual tasks in a fraction of the time it takes a person to complete.  They work around the clock, providing data for your team to action.</p>
                </Col>         
            </Row>
        </AnimationOnScroll>  
      </Container>
      <section className='trial'> 
      <iframe
      src="https://www.youtube.com/embed/1mTW2fUUqeU"
      srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/1mTW2fUUqeU><img src=https://img.youtube.com/vi/1mTW2fUUqeU/hqdefault.jpg 
      alt='Owego the robot bookkeeper'><span>▶</span></a>"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      title="Owego the robot bookkeeper"></iframe>
      <br/>
      <Link to="/demo"><Button variant="danger" size="lg">Schedule a demo &gt;&gt;</Button></Link>
      </section>
      <Container>
            <Col className='intro'>
              <h3><i class="fa-solid fa-lightbulb"></i></h3>
              <br/>
              <h2>6 Reasons Why You Should Deploy A Robot Assistant</h2>
            </Col>
            <AnimationOnScroll duration={2} animateIn="animate__fadeInLeft">
            <Row className='reasons'>            
                <Col xs={1}><h4><i class="fa-solid fa-business-time"></i></h4></Col>
                <Col lg={3} xs={11} sm={11} >
                 <h3>​Do more work without hiring more staff </h3>
                  <p>Your robot assistant decreases the amount of high-volume, repetitive work your human workforce has to do. Because of this, your firm can handle increased demand without adding headcount. </p>
                </Col>
                <Col xs={1}><h4><i class="fa-solid fa-gauge-simple-high"></i></h4></Col> 
                <Col lg={3} xs={11} sm={11}>
                  <h3>Rapid return on your automation investment </h3>
                  <p>On the low end, companies are reporting first year Return on Investments of 30%; and on the high end these ROIs can be up to triple digits and save thousands of pounds a year.</p>
                </Col> 
                <Col xs={1}><h4><i class="fa-solid fa-scale-balanced"></i></h4></Col>
                <Col lg={3} xs={11} sm={11} >
                  <h3>Measurable benefits + 100% compliance</h3>
                  <p>Every action your robot assistant does is logged and tracked enabling us to seamlessly report on the work she completes and determine whether we are achieving agreed KPIs. </p>
                </Col>    
                <Col xs={1}><h4><i class="fa-solid fa-chart-line"></i></h4></Col>      
                <Col lg={3} xs={11} sm={11} >
                 <h3>Start small and work your way up over time  </h3>
                  <p>You don't have to commit to automating every single bookkeeping process in your firm from the start. You can automate a single process, and once you realise the benefits of this kind of automation, you can continue to automate one process after the other. </p>
                </Col> 
                <Col xs={1}><h4><i class="fa-solid fa-bell"></i></h4></Col>
                <Col lg={3} xs={11} sm={11} >
                  <h3>Increased customer  satisfaction </h3>
                  <p>Properly programmed robots don't make mistakes. And since your robot assistant can work many times faster than a person, you will be able to deliver your service in less time. Even better? Your clients get more of what they need most—advisory services and quality time with you!</p>
                </Col> 
                <Col xs={1}><h4><i class="fa-solid fa-infinity"></i></h4></Col>
                <Col lg={3} xs={11} sm={11} >
                  <h3>Almost endless automation possibilities</h3>
                  <p>Your robot assistant can use all of the business applications your firm has uses - whether it is Xero, Quickbooks, FreeAgent, Moneysoft or BrightPay, running on a browser, or it is a legacy system that has been around since the 90's. </p>
                </Col>          
            </Row>
            </AnimationOnScroll>    
      </Container>
      <section class="offering">
        <Container>
            <Col >
            <AnimationOnScroll duration={2} animateIn="animate__fadeIn">
              <h1>VALUE FOR YOU</h1>
              </AnimationOnScroll>
              <p>
              Haibot is the first of its kind as a partner in the
              accounting space. As a force multiplier in your firm, we're
              here to help you scale by adding more revenue to your
              business, without adding the headcount normally
              required to support it. Say goodbye to bookkeeping 
              payroll inefficiencies and hello to scaling your practice.
              </p>
            </Col>
            <AnimationOnScroll duration={1.2} animateIn="animate__fadeIn">
            <Col>
            <h5>
               With a robot assistant, you're able to take on more
               bookkeeping and payroll work without hiring or
               training a full-time bookkeeper.
               <br/> The robot bookkeeper offers:  
            </h5>
            <ul>
              <li><i class="fa-regular fa-circle-check"></i>Invoice processing</li>
              <li><i class="fa-regular fa-circle-check"></i>On-demand P&amp;L, balance sheet, cash flow statements</li>
              <li><i class="fa-regular fa-circle-check"></i>Bank reconciliations</li>
              <li><i class="fa-regular fa-circle-check"></i>Payroll processing &amp; journal entries</li>
              <li><i class="fa-regular fa-circle-check"></i>And so much more!</li>
            </ul>    
            </Col>
            </AnimationOnScroll>
        </Container>
      </section>
      <Container>
        <Col className='intro' xs={{span:10, offset:1}} sm={{ span: 12, offset: 0 }}>
        <AnimationOnScroll duration={2} animateIn="animate__fadeInRight">
          <h1>TRANSFORM YOUR ACCOUNTING FIRM</h1>
        </AnimationOnScroll>
          <p>Technology has significantly changed the accounting industry, allowing accountants and
            <br/> bookkeepers to refocus their resources from traditional bookkeeping and maintenance
            <br/> work to higher-level advisory and analysis of business data. Ultimately, increasing the value provided to clients.
          </p>
          <br/>
          <AnimationOnScroll duration={2} animateIn="animate__fadeInRight">
          <h2>TRANSFORM YOUR BOOKKEEPING WITH HYPERAUTOMATION​</h2>
          </AnimationOnScroll>
          <p>Utilise artificial intelligence and robotic process automation to automate time-consuming
            <br/>repetitive payroll and bookkeeping tasks.  Our hyperautomation-powered robots will be hard at
            <br/>work processing payroll, reconciling accounts, and generating beautiful reports – so you and provided to clients.
            <br/>your team will have more time for what matters most to your clients, and ultimately your
            <br/>business. Our bots work their magic so you and your team can be more human.
          </p>
          <p>Are you ready to transform your firm?</p>
          <Link to="/demo"><Button variant="danger" size="lg">Schedule a demo &gt;&gt;</Button></Link>
        </Col>
      </Container>
      <section class="trial">
        <Container>
        <AnimationOnScroll duration={1.2} animateIn="animate__pulse">
          <em><h3>SPECIAL OFFER</h3></em>
          </AnimationOnScroll>
          <h1>60-Day Trial</h1>
          <br/>
          <p>TRY A ROBOT ASSISTANT "RISK FREE" FOR 60 DAYS</p>
          <Row className='offers'>
              <Col xs={6}>
                <h2><i class="fa-solid fa-group-arrows-rotate"></i></h2>
                <p>We'll help you capture all your payroll and bookkeeping processes </p>
              </Col> 
              <Col xs={6}>
                <h2><i class="fa-solid fa-wrench"></i></h2>
                <p>Watch your robot assistant increase your firm's productivity</p>
              </Col> 
              <Col xs={6}>
                <h2><i class="fa-solid fa-object-group"></i></h2>
                <p>We'll design, build and schedule your robot to run the processes </p>
              </Col> 
              <Col xs={6}>
                <h2><i class="fa-solid fa-globe"></i></h2>
                <p>Focus on getting more business, without worrying about your capacity</p>
              </Col>         
            </Row>
            <Link to="/demo"><Button variant="danger" size="lg">Schedule a demo &gt;&gt;</Button></Link>
        </Container>

      </section>
      <Container>
      <h1 className='faq-intro'>FREQUENTLY ASKED QUESTIONS</h1>
      <Accordion className='faq' defaultActiveKey={['0']}>
        <Accordion.Item eventKey="0">
           <Accordion.Header><h3>Why do I need a Robot Assistant?</h3></Accordion.Header>
           <Accordion.Body>
             Your Robot Assistant can work around the clock, execute processes with speed and accuracy and result in great benefits such as cost savings as you grow and increased efficiency and increased capacity. The consequential benefits of an augmented workforce include increased business growth, ease of compliance and increased customer satisfaction much more enabled by the time freed up from performing mundane manual tasks. All this, and happier, more productive employees!
           </Accordion.Body>                  
        </Accordion.Item>
        <Accordion.Item eventKey="1">
            <Accordion.Header><h3>What kind of work can a Robot Assistant do?</h3></Accordion.Header>
              <Accordion.Body>
              Your Robot Assistant can tackle any task that you can do on a desktop or laptop computer using a keyboard and a mouse. Basically, you can leave all of the manual, repetitive and time-consuming bookkeeping, pre-compliance and payroll tasks make you feel like a robot to your robot assistant and go back to being human again.
              </Accordion.Body>                  
        </Accordion.Item>
        <Accordion.Item eventKey="2">
            <Accordion.Header><h3>How is this technology deployed?</h3></Accordion.Header>
              <Accordion.Body>
                Your Robot Assistant is deployed on the computer where it will be working and connects to your systems through the cloud, so no changes are required to your existing systems.
              </Accordion.Body>                  
        </Accordion.Item>
        <Accordion.Item eventKey="3">
            <Accordion.Header><h3>​How much does it cost?</h3></Accordion.Header>
              <Accordion.Body>
              Our managed service is all inclusive with a simple pricing model - one-time set-up fee per task/process + monthly support fee per robot. Talk to us and be amazed.
              </Accordion.Body>                  
        </Accordion.Item>
        <Accordion.Item eventKey="4">
            <Accordion.Header><h3>​What about security?</h3></Accordion.Header>
              <Accordion.Body>
              Your Robot Assistant is a fast learner but it only does what it is taught. You can therefore be at peace about security. Every transaction on your system is logged and can be audited, and you can see exactly what your robot assistant has been doing.
              </Accordion.Body>                  
        </Accordion.Item>
        <Accordion.Item eventKey="5">
            <Accordion.Header><h3>What is different about Haibot's proposition?</h3></Accordion.Header>
              <Accordion.Body>
              Simply put, we deploy your Robot Assistant with the least amount of disruption. You don’t need to invest in software licenses, hardware, getting help on implementation, maintaining or running the processes daily. Besides, we can train your robot assistant on your process in a matter of days, not weeks or months.
              </Accordion.Body>                  
        </Accordion.Item>
      </Accordion>
      </Container>
      


    </React.Fragment>

  )
}
