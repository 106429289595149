import React from 'react';
import "./thanks.scss"


export default function Thanks() {

    return (
        <div class="jumbotron text-center" >
          <h1 class="display-3">Thank You!</h1>
          <p class="lead">Our support team would get back to you in no time.</p>
          <hr/>
          <p class="lead"><a class="btn btn-primary btn-sm" href="/home" role="button">Continue to homepage</a></p>
        </div>

    )
}